<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-4">
              <label>No seri pajak</label>
              <InputText
                v-model="form.pajak_no"
                :class="{ 'p-invalid': v$.form.pajak_no.$invalid && submitted }"
                type="text"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.pajak_no.$invalid && submitted) ||
                  v$.form.pajak_no.$pending.$response
                "
                class="p-error"
                >{{ v$.form.pajak_no.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-4">
              <label>Masa berlaku</label>
              <Calendar
                v-model="form.expired_at"
                dateFormat="dd/mm/yy"
                class="w-full"
                locale="id-ID"
                :class="{
                  'p-invalid': v$.form.expired_at.$invalid && submitted,
                }"
              />
              <small
                v-if="
                  (v$.form.expired_at.$invalid && submitted) ||
                  v$.form.expired_at.$pending.$response
                "
                class="p-error"
                >{{ v$.form.expired_at.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-4">
              <label>Jumlah nomor</label>
              <InputNumber
                v-model="form.quantity"
                :class="{ 'p-invalid': v$.form.quantity.$invalid && submitted }"
                :minFractionDigits="0"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.quantity.$invalid && submitted) ||
                  v$.form.quantity.$pending.$response
                "
                class="p-error"
                >{{ v$.form.quantity.required.$message }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Generate"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('generate', this.form)
    },
  },
  validations() {
    return {
      form: {
        pajak_no: {
          required: helpers.withMessage('Nilai harus diisi.', required),
        },
        expired_at: {
          required: helpers.withMessage('Nilai harus diisi.', required),
        },
        quantity: {
          required: helpers.withMessage('Nilai harus diisi.', required),
        },
      },
    }
  },
}
</script>

<style>
.p-combobox-label {
  position: relative;
  width: calc(100% - 24px);
}

.p-combobox-label-container {
  width: 100%;
  position: relative;
}

.p-combobox-icon-remove {
  position: absolute;
  top: 2px;
  right: 0;
  color: red;
}
</style>
